<template>
    <span>{{currentYear}}</span>
</template>

<script>
    import {
    	isBlank
    }
    from 'voUtils/tools.js';
    import V from 'voUtils/V.js'


    import DateEx from 'voUtils/DateEx.js'

    export default {
    	voVueComponent: 'current-year',


    	computed: {
    		currentYear: function() {
    			var d = new Date();
    			return d.getFullYear();
    		}

    	},

    	methods: {}
    }
</script>